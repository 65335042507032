<template>
  <!-- 新增/修改客户信息 -->
  <el-dialog
    title="编辑用户报告数据"
    :close-on-click-modal="false"
    :visible.sync="visible"
    center
    width="600px"
    v-loading="dialogLoadingFlag"
  >
    <el-form
      ref="dataForm"
      :rules="dataRule"
      :model="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="130px"
    >
      <el-form-item label="编号" prop="sampleId">
        <el-input
          v-model.trim="dataForm.numbering"
          readonly
          class="w250"
        ></el-input>
      </el-form-item>
      <!-- 男性 -->
      <template v-if="mainData.sex == '男'">
        <el-form-item label="AMH" prop="amh">
        <el-input-number
          v-model="dataForm.amh"
          :min='0.1'
        ></el-input-number>
      </el-form-item>
      <el-form-item label="FSH" prop="fsh">
        <el-input-number
          v-model="dataForm.fsh"
          :min='0'
        ></el-input-number>
      </el-form-item>
      <el-form-item label="DFI" prop="dfi">
        <el-input-number
          v-model="dataForm.dfi"
          :min='0.1'
        ></el-input-number>
      </el-form-item>
      </template>
      <!-- 女性 -->
      <template v-if="mainData.sex == '女'">
        <el-form-item label="AMH" prop="amh">
        <el-input-number
          v-model="dataForm.amh"
          :min='0.1'
        ></el-input-number>
      </el-form-item>
      <el-form-item label="FSH" prop="fsh">
        <el-input-number
          v-model="dataForm.fsh"
          :min='0'
        ></el-input-number>
      </el-form-item>
      <el-form-item label="LH" prop="lh">
        <el-input-number
          v-model="dataForm.lh"
          :min='0'
        ></el-input-number>
      </el-form-item>
      </template>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :loading="loadingFlag"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      mainData: {},
      dataForm: {
        id: '',
        dfi: '',
        amh: '',
        fsh: '',
        lh: '',
      },
      dataRule: {
        dfi: [
          { required: true, message: '请输入dfi', trigger: 'blur' }
        ],
        amh: [
          { required: true, message: '请输入amh', trigger: 'blur' }
        ],
        fsh: [
          { required: true, message: '请输入fsh', trigger: 'blur' }
        ],
        lh: [
          { required: true, message: '请输入lh', trigger: 'blur' }
        ]
      },
      dialogLoadingFlag: false,
      loadingFlag: false,
    };
  },
  created() {},
  methods: {
    init(row) {
      this.visible = true;
      this.dataForm = {
        id: row.id,
        numbering: row.numbering,
        // 设置默认值
        tex101: '',
        proAKAp4: '',
        dfi: '',
        amh: '',
        fsh: '',
        lh: ''
      };
      this.mainData = row
      if(row.sex == '男') {
        this.dataForm.amh = row.amh
        this.dataForm.fsh = row.fsh
        this.dataForm.dfi = row.dfi
      } else if(row.sex == '女') {
        this.dataForm.amh = row.amh
        this.dataForm.fsh = row.fsh
        this.dataForm.lh = row.lh
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let url = "/user-test-data/editUserTestData";
          let data = {
            id: this.dataForm.id,
            numbering: this.dataForm.numbering,
          };
          if(this.mainData.sex == '男') {
            data.amh = Number(this.dataForm.amh)
            data.fsh = Number(this.dataForm.fsh)
            data.dfi = Number(this.dataForm.dfi)
          } else {
            data.amh = Number(this.dataForm.amh)
            data.fsh = Number(this.dataForm.fsh)
            data.lh = Number(this.dataForm.lh)
          }
          this.loadingFlag = true;
          this.$http.post(`${url}`, data).then((res) => {
            this.loadingFlag = false;
            if (res.data.code == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.visible = false;
              this.$emit("getDataList");
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
