<template>
  <el-dialog
    title="查看话术"
    :close-on-click-modal="false"
    :visible.sync="visible"
    center
    width="1000px"
  >
    <div class="dialog-container">
      <div v-for="item in listData" :key="item.detectionItem">
        <div class="talk-title">{{ item.detectionItem }}</div>
        <div class="talk-content" v-html="item.content"></div>
      </div>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="visible=false">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      listData: [],
      visible: false,
    };
  },
  methods: {
    init(id) {
      this.getDataList(id);
      this.listData = []
      this.visible = true;
    },
    /**
     * 加载列表数据数据
     */
    getDataList(id) {
      let loginUserInfo = this.$store.state.loginUserInfo;
      const resData = {
          id: id,
          customerId: loginUserInfo.isAdmin ? 'ALL' : (loginUserInfo.userId || null),
        }
      this.$http
        .post(
          `dbVerbalTrick/selReportVerbalTrick`, resData)
        .then((res) => {
          if (res.data.code === 200) {
            this.listData = res.data.data;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.talk-title {
  color: #CF696C;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}
.talk-content {
  padding: 0 20px;
  margin-bottom: 20px;
}
</style>
