<template>
  <div>
    <div slot="header" class="header-container">
        <div class="query">
          <el-input
            class="input-field"
            clearable
            :placeholder="showContents.numbering"
            v-model.trim="queryData.numbering"
            @keyup.enter.native="getDataList(true)"
          ></el-input>
          <el-input
            class="input-field"
            clearable
            :placeholder="showContents.keyWord"
            v-model.trim="queryData.keyWord"
            @keyup.enter.native="getDataList(true)"
          ></el-input>

          <el-date-picker
            class="date-picker form_item"
            v-model="startTimeAndEndTime"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="-"
            :start-placeholder="showContents.startDate"
            :end-placeholder="showContents.endDate"
            @change="getDataList(true)"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
          <el-select class="select-field form_item" filterable clearable v-model="queryData.userLoginId" placeholder="客户" v-if="isAuth('view:customers')">
            <el-option :label="item.name" :value="item.id" v-for="item in channelSelList" :key="item.id"> </el-option>
          </el-select>
          <el-select class="select-field form_item" v-model="queryData.isPush" placeholder="推送状态">
            <el-option label="已推送" value="2"> </el-option>
            <el-option label="未推送" value="1"> </el-option>
          </el-select>

          <el-button type="success" class="ml10" @click="getDataList(true)">{{ showContents.queryBtn }}</el-button>
          <el-button class="op_btn" type="primary" @click="pushReport()">{{ showContents.batchPushBtn }}</el-button>
          <el-dropdown>
              <el-button type="primary" class="op_btn ml10">
                批量操作<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="exportData2()">{{ showContents.dcxzdbgBtn }}</el-dropdown-item>
                <el-dropdown-item @click.native="exportData3()">{{ showContents.dcxzdyhsjBtn }}</el-dropdown-item>
                <!-- <el-dropdown-item v-if="isAuth('exportCsv')" @click.native="exportCsv()">{{ showContents.dcCsvBtn }}</el-dropdown-item> -->
                <!-- <el-dropdown-item @click.native="pushReport()">{{ showContents.batchPushBtn }}</el-dropdown-item> -->
                <el-dropdown-item v-if="isAuth('importCsvData')"><el-upload
                  class="upload-demo"
                  :action="`${$store.state.baseUrl}user-test-data/ImportCsvData/${getUserId()}`"
                  :show-file-list="false"
                  accept=".xls,.xlsx"
                  :on-success="uploadSuccess"
                  :on-error="$uploadError"
                  :before-upload="$beforeUploadFile"
                >
                  {{ showContents.xjqdrCsvBtn }}
                </el-upload></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          <!-- <el-button class="op_btn" type="primary" @click="exportData2()">{{ showContents.dcxzdbgBtn }}</el-button>
          <el-button class="op_btn" type="primary" @click="exportData3()">{{ showContents.dcxzdyhsjBtn }}</el-button>
          <el-button class="op_btn" type="primary" v-if="isAuth('exportCsv')" @click="exportCsv()">{{ showContents.dcCsvBtn }}</el-button> -->

          <!-- <el-upload
            v-if="isAuth('importCsvData')"
            class="upload-demo op_btn ml10"
            :action="`${$store.state.baseUrl}user-test-data/ImportCsvData/${getUserId()}`"
            :show-file-list="false"
            accept=".xls,.xlsx"
            :on-success="uploadSuccess"
            :on-error="$uploadError"
            :before-upload="$beforeUploadFile"
          >
            <el-button type="primary">{{ showContents.xjqdrCsvBtn }}</el-button>
          </el-upload> -->

          <!-- <el-button class="op_btn" type="primary" @click="pushReport()">{{ showContents.batchPushBtn }}</el-button> -->
        </div>
      </div>

    <!-- 移动端展示 -->
    <div class="mobile-cards">
      <el-card
        v-for="(item, index) in listData"
        :key="index"
        class="woman-card"
        shadow="hover"
      >
        <div class="card-content">
          <div class="card-header">
            <!-- <el-dropdown>
              <el-button type="text" class="el-dropdown-link">
                操作<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="showTalk(item)">{{ showContents.talkBtn }}</el-dropdown-item>
                <el-dropdown-item @click.native="pushReport(item)">{{ showContents.pushBtn }}</el-dropdown-item>
                <el-dropdown-item @click.native="openUrl(item)">{{ showContents.checkBtn }}</el-dropdown-item>
                <el-dropdown-item @click.native="downloadReport(item)">{{ showContents.downloadReportBtn }}</el-dropdown-item>
                <el-dropdown-item @click.native="del(item.id)">{{ showContents.deleteBtn }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
          </div>
          <div class="card-item">
            <div class="flex align_center pb10 mb10 br_bottom pl10 pr10">
              <p class="item_seq">{{ getSeq(index, queryData.current, queryData.size) }}</p>
              <p class="ml10 mr10">{{ item.userName }}</p>
              <p class="ml10 mr10">{{ $store.state.language == "en" ? "Female" : "女" }}</p>
              <p class="ml10 mr10">{{ item.age }}{{ showContents.tableAgeUnit }}</p>
              <p v-if="$store.state.language == 'zh'" class=" flex1 t_r" :class="item.isPush == 1 ? 'color_red' : 'color_green'" @click="pushReport(item)">{{ item.isPush == 1 ? '未推送' : '已推送' }}</p>
            </div>
            <div class="pl10 pr10 pb10 br_bottom mb10">
              <p class="mb10">条码号: {{ item.numbering }}<span class="font16 ml3" :class="checkReport(item).color" @click="openReportUrl(item)"><i class="el-icon-document"></i></span></p>
              <p class="mb10">AMH: {{ item.amh }}</p>
              <p class="mb10">FSH: {{ item.fsh }}</p>
              <p class="mb10">LH: {{ item.lh }}</p>
              <p class="mb10">检测时间: {{ item.detectionTime | dateFormat1 }}</p>
              <p>创建时间: {{ item.createTime | dateFormat1 }}</p>
            </div>
            <div class="flex align_center">
              <div class="flex1 t_c" @click="openReportUrl(item)">
                <p class="font18 color_blue"><i class="el-icon-document"></i></p>
                <p>查看报告</p>
              </div>
              <div class="flex1 t_c" @click="showTalk(item)">
                <p class="font18 color_yellow"><i class="el-icon-chat-line-square"></i></p>
                <p>{{ showContents.talkBtn }}</p>
              </div>
              <div class="flex1 t_c" @click="showHistory(item)">
                <p class="font18 color_gray"><i class="el-icon-notebook-2"></i></p>
                <p>{{ showContents.historyBtn }}</p>
              </div>

            </div>
          </div>
        </div>
      </el-card>
    </div>

    <!-- 桌面端表格展示 -->
    <el-table
      class="pc-table"
      :data="listData"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      border
      stripe
      style="margin-top: 20px"
      ref="multipleTable"
      tooltip-effect="dark"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        prop="userLoginName"
        :label="showContents.userLoginName"
        min-width="120"
        v-if="isAuth('view:customers')"
      ></el-table-column>
      <el-table-column
        prop="numbering"
        :label="showContents.tableBarCode"
        min-width="120"
      >
        <template slot-scope="scope">
          {{ scope.row.numbering }}
          <el-tooltip class="item" effect="dark" :content="checkReport(scope.row).title" placement="top">
                  <span class="font20" :class="checkReport(scope.row).color" @click="openReportUrl(scope.row)"><i class="el-icon-document"></i></span>
          </el-tooltip>
          <!-- <el-button
            type="text"
            v-if="isAuth('修改条码')"
            @click="editNumberIng(scope.row.id)"
            >{{ showContents.tableBarCodeBtn }}</el-button
          > -->
        </template>
      </el-table-column>
      <el-table-column
        prop="userName"
        :label="showContents.tableUserName"
        min-width="120"
      ></el-table-column>
      <el-table-column
        prop="sex"
        :label="showContents.tableSex"
        min-width="100"
      >
        <template slot-scope="scope">
          <el-tag>{{ $store.state.language == "en" ? "Female" : "女" }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="age"
        :label="showContents.tableAge"
        min-width="100"
      >
        <template slot-scope="scope">
          <p>{{ scope.row.age }}</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="amh"
        :label="`amh${showContents.valueTxt}`"
        min-width="120"
      ></el-table-column>
      <el-table-column
        prop="fsh"
        :label="`fsh${showContents.valueTxt}`"
        min-width="120"
      ></el-table-column>
      <el-table-column
        prop="lh"
        :label="`lh${showContents.valueTxt}`"
        min-width="120"
        v-if="$store.state.language == 'zh'"
      ></el-table-column>
      <el-table-column
        prop="isPush"
        :label="showContents.pushText"
        min-width="120"
        v-if="$store.state.language == 'zh'"
      >
        <template slot-scope="scope">
          <p class="color_red" v-if="scope.row.isPush == 1">未推送</p>
          <p class="color_green" v-else-if="scope.row.isPush == 2">已推送</p>
          <p v-else>--</p>
        </template>
      </el-table-column>
      <el-table-column :label="showContents.detectionTime" min-width="160">
        <template slot-scope="scope">
          <p v-if="scope.row.detectionTime">
            {{ scope.row.detectionTime | dateFormat1 }}
          </p>
          <el-tag v-else>{{ showContents.detectionTimeTxt }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="showContents.createTime" min-width="160">
        <template slot-scope="scope">
          <p>{{ scope.row.createTime | dateFormat1 }}</p>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        prop="operation"
        :label="showContents.operation"
        width="320px;"
      >
        <template slot-scope="scope">
          <div class="tableOperation">
            <!-- <el-button type="text" @click="showHistory(scope.row)">{{
              showContents.historyBtn
            }}</el-button>
            <span class="operationLine">|</span>
            <el-button type="text" @click="showTalk(scope.row)">{{
              showContents.talkBtn
            }}</el-button> -->
            <!-- <span class="operationLine">|</span> -->
            <el-button type="text" @click="pushReport(scope.row)">{{
              showContents.pushBtn
            }}</el-button>
            <span class="operationLine">|</span>
            <el-button type="text" @click="openUrl(scope.row)">{{
              showContents.checkBtn
            }}</el-button>
            <span class="operationLine">|</span>
            <el-button type="text" @click="downloadReport(scope.row)">{{
              showContents.downloadReportBtn
            }}</el-button>
            <span class="operationLine">|</span>
            <!-- <el-button type="text" @click="downloadReport(scope.row)">{{
              showContents.downloadReportBtn
            }}</el-button>
            <span class="operationLine">|</span>
            <el-button class="F56C6C" type="text" @click="del(scope.row.id)">{{
              showContents.deleteBtn
            }}</el-button> -->
            <el-dropdown >
                <el-button type="text">操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="isAuth('修改条码')" @click.native="editNumberIng(scope.row.id)">{{ showContents.tableBarCodeBtn }}</el-dropdown-item>
                <el-dropdown-item v-if="isAuth('report:edit') || $store?.state?.loginUserInfo?.isEditReport" @click.native="editReport(scope.row)">修改报告</el-dropdown-item>
                <el-dropdown-item @click.native="showHistory(scope.row)">{{ showContents.historyBtn }}</el-dropdown-item>
                <el-dropdown-item @click.native="showTalk(scope.row)">{{ showContents.talkBtn }}</el-dropdown-item>
                <!-- <el-dropdown-item @click.native="downloadReport(scope.row)">{{ showContents.downloadReportBtn }}</el-dropdown-item> -->
                <el-dropdown-item @click.native="del(scope.row.id)"><span class="color_red">{{ showContents.deleteBtn }}</span></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!--分页-->
    <el-pagination
      class="floatRight"
      v-if="total > 20"
      @size-change="getDataListSizeChange"
      @current-change="getDataListCurrentChange"
      :current-page.sync="queryData.current"
      :page-sizes="[20, 50, 100, 200]"
      :page-size="queryData.size"
      :pager-count="5"
      :total="total"
      layout="total,sizes, prev, pager, next"
    ></el-pagination>
    <bone-health-vue ref="boneHealthVue"></bone-health-vue>
    <!-- 话术列表 -->
    <showTalk ref="showTalkRef" ></showTalk>
    <!-- 历史对比列表 -->
    <showHistory ref="showHistoryRef" ></showHistory>
    <!-- 修改报告 -->
    <editReport ref="editReportRef" @getDataList="getDataList"></editReport>
  </div>
</template>

<script>
import boneHealthVue from "./boneHealth.vue";
import showTalk  from "./showTalk";
import showHistory  from "./showHistory";
import editReport from "./editReport";
import { downloadZip } from '@/utils/exportFileZip.js';
export default {
  components: {
    boneHealthVue,
    showTalk,
    showHistory,
    editReport
  },
  data() {
    return {
      channelSelList: [],
      loading: false,
      listData: [],
      total: 0,
      queryData: {
        size: 20,
        current: 1,
        sex: "女",
        userLoginId: "",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      startTimeAndEndTime: [],
      selectionList: [],
      isPushAuth: false,

      showContents: {
        keyWord: "请输入昵称/手机号/邮箱",
        numbering: "条码号",
        startDate: "开始日期",
        endDate: "结束日期",
        queryBtn: "查询",
        dcbgBtn: "导出报告",
        dcxzdbgBtn: "导出选中的报告",
        dcxzdyhsjBtn: "导出选中的用户数据",
        dcCsvBtn: "导出Csv",
        xjqdrCsvBtn: "新机器导入Excel",
        userLoginName: "客户",
        tableBarCode: "条码号",
        tableBarCodeBtn: "修改条码",
        tableUserName: "姓名",
        tableSex: "性别",
        tableAge: "年龄",
        tableAgeUnit: "岁",
        valueTxt: "值",
        tableSfts: "是否推送",
        tableZljy: "诊疗建议",
        tableZljyWlx: "未填写",
        tableGgjk: "骨骼健康",
        tableLanguage: "语言",
        tableLanguageTxt1: "中文",
        tableLanguageTxt2: "英文",
        detectionTime: "报告时间",
        detectionTimeTxt: "待检测",
        createTime: "创建时间",
        operation: "操作",

        checkBtn: "查看",
        downloadReportBtn: "下载报告",
        deleteBtn: "删除",
        pushOperation: "推送操作",
        tsdqfyy: "推送到曲阜医院",
        pushBtn: "推送",
        pushText: "推送状态",
        batchPushBtn: "推送选中的报告",
        talkBtn: '话术',
        historyBtn: '历史对比'
      },
      czsbqcshlxgly:
        this.$store.state.language == "zh"
          ? "操作失败，请重试或联系管理员"
          : "Operation failed, please try again or contact the administrator",
    };
  },
  created() {},
  mounted() {
    if (
      this.isAuth("推送操作") ||
      this.getQueryId() == "1427916520868024321" ||
      this.getQueryId() == "1402455457745866753"
    ) {
      this.isPushAuth = true;
    }
  },
  methods: {
    init() {
      if (this.getUserRoleName() == "客户") {
        this.$set(this.queryData, "userLoginId", this.getQueryId());
      }
      if(this.isAuth('view:customers')) { 
        this.getChannelSelList()
      }
      this.getDataList();
      if (this.$store.state.language == "en") {
        this.showContents = {
          keyWord: "Please enter your username/phone number/email",
          numbering: "Barcode",
          startDate: "Start date",
          endDate: "End date",
          dcbgBtn: "Export report",
          dcxzdbgBtn: "Export the selected report",
          dcxzdyhsjBtn: "Export selected user data",
          dcCsvBtn: "Export Csv",
          xjqdrCsvBtn: "Test Data Imput ",
          queryBtn: "query",
          tableBarCode: "Barcode",
          tableBarCodeBtn: "edit",
          tableUserName: "Username",
          tableSex: "Gender",
          tableAge: "age",
          tableAgeUnit: "year",
          valueTxt: " Level",
          tableSfts: "Whether to push",
          tableZljy: "Diagnosis and treatment recommendations",
          tableZljyWlx: "Not filled in",
          tableGgjk: "Skeletal health",
          tableLanguage: "tableLanguage",
          tableLanguageTxt1: "Chinese",
          tableLanguageTxt2: "English",
          detectionTime: "Report time",
          detectionTimeTxt: "To be tested",
          detectionTime: "Report time",
          createTime: "Creation time",
          operation: "operation",
          checkBtn: "Report",
          downloadReportBtn: "downloadReport",
          deleteBtn: "Delete",
          pushOperation: "push operation",
          tsdqfyy: "Push to Qufu Hospital",
        };
      }
    },
    checkReport(row) {
      const data = {
        title: '',
        color: ''
      }
      if(row.reportStatus === '2') {
          data.title = '查看报告'
          data.color = 'color_green cursor_pointer'
      }else if(row.reportStatus === '3') {
          data.title = '报告异常'
          data.color = 'color_red'
      }else {
        data.title = '暂无报告'
        data.color = ''
      }
      return data
    },
    openReportUrl(row) {
      if(row.reportStatus === '2') {
        window.open(row.reportUrl)
      }else {
        this.$message({message: '报告格式错误', type: 'warning'})
      }
    },
    editReport(row) {
      this.$refs.editReportRef.init(row)
    },
    boneHealthPopup(numbering) {
      this.$nextTick(() => {
        this.$refs.boneHealthVue.init(numbering);
      });
    },
    uploadSuccess(res) {
      this.$store.state.beforeUploadLoading.close();
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: "导入成功!",
        });
        this.getDataList(true);
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    editNumberIng(id) {
      this.$prompt("请输入新条码", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.$http
            .put(`user-test-data/updateNumberingById`, {
              id,
              numbering: value,
            })
            .then((res) => {
              if (res.data.code == 200) {
                this.getDataList();
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消修改",
          });
        });
    },
    pushReportQuFu(id) {
      if (id) {
        this.$confirm(`是否推送数据？`, "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const loadLoading = this.$loading({
              lock: true,
              text: "推送中...",
              spinner: "el-icon-loading",
            });
            this.$http
              .post(`user-test-data/pushReportQuFu/${id}`)
              .then((res) => {
                loadLoading.close();
                if (res.data.code == 200) {
                  this.getDataList();
                  this.$message({
                    type: "success",
                    message: "推送成功!",
                  });
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "warning",
                  });
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },
    updateMenopausalAge(id, menopausalAge) {
      if (id) {
        this.$confirm(`是否修改所选择的数据？`, "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$http
              .put(`user-test-data/updateById`, {
                id: id,
                menopausalAge: menopausalAge ? false : true,
              })
              .then((res) => {
                if (res.data.code == 200) {
                  this.getDataList();
                  this.$message({
                    type: "success",
                    message: "修改成功!",
                  });
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "warning",
                  });
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    handleSelectionChange(e) {
      this.selectionList = e;
    },
    /**
     * 推送报告
     */
    pushReport(row) {
      let idList = []
      if (row?.id) {
        idList.push(row.id)
      }else {
        if (this.selectionList?.length === 0) {
          this.$message({
            message: "请选择需要推送的数据",
            type: "warning",
          });
          return
        }
        idList = this.selectionList.map(item => item.id)
      }
      this.$confirm("是否推送所选择的数据?", "温馨提示",
          {
            confirmButtonText:
              this.$store.state.language == "en" ? "confirm" : "确定",
            cancelButtonText:
              this.$store.state.language == "en" ? "cancel" : "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.$http
              .post(`user-test-data/pushUserTestData`, {idList: idList, customerId: this.$store.state.loginUserInfo.id})
              .then((res) => {
                if (res.data.code == 200) {
                  this.getDataList();
                  this.$message({
                    type: "success",
                    message:"推送成功",
                  });
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "warning",
                  });
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message:
                this.$store.state.language == "en"
                  ? "Cancel operation"
                  : "取消操作",
            });
          });
    },
    /**
     * 查看话术
     */
    showTalk(row) {
      this.$nextTick(() => {
          this.$refs.showTalkRef.init(row.id);
        });
    },
    /**
     * 查看历史对比数据
     */
    showHistory(row) {
      this.$nextTick(() => {
          this.$refs.showHistoryRef.init({userId: row.userId, sex: '女'});
        });
    },
    downloadReport(row) {
      let url = "";

      if (location.host.indexOf("admin.predicthealthen.com") !== -1)
        url = `https://api.predicthealth.cn/user-test-data/exportPdfById/${row.id}?version=EN`;
      else {
        url = `https://api.predicthealth.cn/user-test-data/exportPdfById/${row.id}`;
      }

      window.open(`${url}`);
    },
    openUrl(row) {
      let url = "";
      if (location.host.indexOf("admin.predicthealthen.com") !== -1) {
        url = `https://en.predicthealth.cn`;
      } else {
        url = `https://360.predicthealth.cn`;
      }
      window.open(`${url}/WomenHealth?id=${row.id}`);
    },
    exportData3() {
      if (this.selectionList.length > 0) {
        const downloadLoading = this.$loading({
          lock: true,
          text: "正在导出，数据量过大时请耐心等待......",
          spinner: "el-icon-loading",
        });
        let list = this.selectionList;
        let ids = [];
        list.forEach((item) => {
          ids.push(item.id);
        });
        this.$http
          .post(`/user-test-data/exportUserData`, ids, {
            responseType: "blob",
          })
          .then((res) => {
            if (res.data != null) {
              let blob = new Blob([res.data], {
                type: "application/vnd.ms-excel",
              });

              let url = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = url;
              link.download =
                this.getTodayTime2(new Date()) + "用户数据" + ".xlsx";
              link.click();
              URL.revokeObjectURL(url);
            } else
              this.$message({
                message: this.czsbqcshlxgly,
                type: "warning",
              });
            downloadLoading.close();
          })
          .catch((err) => {
            this.$message({
              message: this.czsbqcshlxgly,
              type: "warning",
            });
            downloadLoading.close();
          });
      } else
        this.$message({
          message: "请选择需要导出的数据",
          type: "warning",
        });
    },
   async exportData2() {
      
      if (this.selectionList.length > 0) {
        const downloadLoading = this.$loading({
          lock: true,
          text: "正在导出，数据量过大时请耐心等待......",
          spinner: "el-icon-loading",
        });
      this.selectionList.forEach(item => {
        item.fileName = item.userName +  '-' + item.numbering + '.pdf';
      });
      await downloadZip(this.selectionList, this.getTodayTime2(new Date()) + "女性检测数据" + ".zip", 'reportUrl', 'fileName');
      downloadLoading.close();
      return
        let list = this.selectionList;
        let ids = [];
        list.forEach((item) => {
          ids.push(item.id);
        });
        this.$http
          .post(`/user-test-data/exportPdfByIds`, ids, {
            responseType: "blob",
          })
          .then((res) => {
            if (res.data != null) {
              let blob = new Blob([res.data], {
                type: "application/zip",
              });

              let url = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = url;
              link.download =
                this.getTodayTime2(new Date()) + "女性检测数据" + ".zip";
              link.click();
              URL.revokeObjectURL(url);
            } else
              this.$message({
                message: this.czsbqcshlxgly,
                type: "warning",
              });
            downloadLoading.close();
          })
          .catch((err) => {
            this.$message({
              message: this.czsbqcshlxgly,
              type: "warning",
            });
            downloadLoading.close();
          });
      } else
        this.$message({
          message: "请选择需要导出的数据",
          type: "warning",
        });
    },
    exportData1() {
      const downloadLoading = this.$loading({
        lock: true,
        text: "正在导出，数据量过大时请耐心等待......",
        spinner: "el-icon-loading",
      });
      let data = this.queryData;
      if (this.startTimeAndEndTime && this.startTimeAndEndTime.length > 0) {
        let timeArr = this.startTimeAndEndTime;
        data.startTime = timeArr[0];
        data.endTime = timeArr[1];
      } else {
        data.startTime = "";
        data.endTime = "";
      }
      this.$http
        .get(
          `user-test-data/exportPdf?startTime=${encodeURIComponent(
            data.startTime
          )}&endTime=${encodeURIComponent(
            data.endTime
          )}&sex=${encodeURIComponent(data.sex)}&userLoginId=${
            data.userLoginId
          }`,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          if (res.data != null) {
            let blob = new Blob([res.data], {
              type: "application/zip",
            });

            let url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download =
              this.getTodayTime2(new Date()) + "女性检测数据" + ".zip";
            link.click();
            URL.revokeObjectURL(url);
          } else
            this.$message({
              message: this.czsbqcshlxgly,
              type: "warning",
            });
          downloadLoading.close();
        })
        .catch((err) => {
          this.$message({
            message: this.czsbqcshlxgly,
            type: "warning",
          });
          downloadLoading.close();
        });
    },
    exportCsv() {
      const downloadLoading = this.$loading({
        lock: true,
        text: "正在导出，数据量过大时请耐心等待......",
        spinner: "el-icon-loading",
      });
      let data = this.queryData;
      if (this.startTimeAndEndTime && this.startTimeAndEndTime.length > 0) {
        let timeArr = this.startTimeAndEndTime;
        data.startTime = timeArr[0];
        data.endTime = timeArr[1];
      } else {
        data.startTime = "";
        data.endTime = "";
      }
      this.$http
        .get(
          `user-test-data/exportCsv?startTime=${encodeURIComponent(
            data.startTime
          )}&endTime=${encodeURIComponent(
            data.endTime
          )}&sex=${encodeURIComponent(data.sex)}&userLoginId=${
            data.userLoginId
          }`,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          if (res.data != null) {
            let blob = new Blob([res.data], {
              type: "application/csv",
            });

            let url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download =
              this.getTodayTime2(new Date()) + "女性检测数据" + ".csv";
            link.click();
            URL.revokeObjectURL(url);
          } else
            this.$message({
              message: this.czsbqcshlxgly,
              type: "warning",
            });
          downloadLoading.close();
        })
        .catch((err) => {
          this.$message({
            message: this.czsbqcshlxgly,
            type: "warning",
          });
          downloadLoading.close();
        });
    },
    /**
     * 删除数据
     */
    del(id) {
      if (id) {
        this.$confirm(
          this.$store.state.language == "en"
            ? "Do you want to delete the selected data？"
            : "是否删除所选择的数据?",

          this.$store.state.language == "en" ? "Kind reminder" : "温馨提示",
          {
            confirmButtonText:
              this.$store.state.language == "en" ? "confirm" : "确定",
            cancelButtonText:
              this.$store.state.language == "en" ? "cancel" : "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.$http
              .delete(`user-test-data/baseDeleteById/${id}`)
              .then((res) => {
                if (res.data.code == 200) {
                  this.getDataList();
                  this.$message({
                    type: "success",
                    message:
                      this.$store.state.language == "en"
                        ? "Delete successful"
                        : "删除成功",
                  });
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "warning",
                  });
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message:
                this.$store.state.language == "en"
                  ? "Cancel operation"
                  : "取消操作",
            });
          });
      }
    },
    /**
     * 加载列表数据数据
     * @flag {boolean} 是否设置current为1
     */
    getDataList(flag) {
      if (flag) this.queryData.current = 1;
      this.loading = true;
      let data = this.queryData;
      if (this.startTimeAndEndTime && this.startTimeAndEndTime.length > 0) {
        let timeArr = this.startTimeAndEndTime;
        data.startTime = timeArr[0];
        data.endTime = timeArr[1];
      } else {
        data.startTime = "";
        data.endTime = "";
      }
      if (data.keyWord) data.keyWord = data.keyWord.trim();
      this.$http
        .get(`user-test-data/getSelectPage`, {
          params: data,
        })
        .then((res) => {
          if (res.data.code === 200) {
            let data = res.data.data;
            if (data) {
              this.listData = data.records;
              this.total = data.total;
            }
            this.loading = false;
          }
        });
    },
     /**
     *  获取客户列表数据
     * 
     */
     getChannelSelList() {
      this.$http
        .get(`user-login/getChannelSelList`, {})
        .then((res) => {
          if (res.data.code === 200) {
            let data = res.data.data;
            this.channelSelList = data
          }
        });
    },
    getDataListSizeChange(val) {
      this.queryData.size = val;
      this.getDataList(true);
    },
    getDataListCurrentChange(val) {
      this.queryData.current = val;
      this.getDataList();
    },
  },
};
</script>

<style scoped>
.pc-table {
  display: block;
}

.mobile-cards {
  display: none;
}

.el-card {
  border-radius: 8px;
  overflow: hidden;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .pc-table {
    display: none;
  }
  .mobile-cards {
    display: block;
  }
}
.header-container {
  position: relative;
  padding: 10px;
}

.query {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.input-field,
.date-picker,
.select-field {
  width: 100%;
}

.ml10 {
  margin-left: 0;
}

.op_btn {
  width: 100%;
}

/* 媒体查询 */
@media (min-width: 768px) {
  .query {
    flex-direction: row;
    gap: 0;
  }

  .input-field,
  .date-picker,
  .select-field {
    width: auto;
  }

  .ml10 {
    margin-left: 10px;
  }

  .op_btn {
    width: auto;
  }
}
@media (max-width: 768px) {
   :deep .el-card {
    .el-card__body {
      padding: 10px;
    }
    .mobile-cards {
      .el-card__body {
        padding: 20px 0;
      }
    }
  }
  .input-field {
    flex: 1 !important;
  }
}
</style>